function hideshow(value) {
    if (value != '') {
        jQuery('#proof_view').removeClass('hide');
    } else {
        jQuery('#proof_view').addClass('hide');
    }
}

function openmodal(value) {
    if (value != '') {
        jQuery('#modal_theme_primary').modal({
            show: true
        });
    }
}
//for empty otp textbox
jQuery(document).on("click", ".resend_value", function () {
    jQuery('#otp_value').val('');
});
jQuery(document).ready(function () {
    jQuery('[data-toggle="tooltip"]').tooltip();
});

jQuery("#name").on('keyup', function () {
    var Text = jQuery(this).val();
    Text = Text.toLowerCase();
    Text = Text.replace(/[^a-zA-Z0-9]+/g, '-');
    jQuery("#slug").val(Text);
});
/* Delete Time Model Open Code */
(function () {
    var laravel = {
        initialize: function () {
            this.methodLinks = $('body');
            this.registerEvents();
        },
        registerEvents: function () {
            //this.methodLinks.on('click', this.handleMethod);
            this.methodLinks.on('click', 'a[data-method]', this.handleMethod);
        },
        handleMethod: function (e) {
            e.preventDefault();
            var link = $(this);
            var csrf_token = jQuery('meta[name="csrf-token"]').attr('content');
            var httpMethod = link.data('method').toUpperCase();
            var allowedMethods = ['PUT', 'DELETE', 'GET'];
            var extraMsg = link.data('modal-text');
            var reject = link.data('reject');
            var confirm = link.data('confirm');
            var is_delete_with_reason = link.data('delete-reason');
            console.log(is_delete_with_reason);
            if (httpMethod == "GET") {
                delete_msg = "Are you sure you want to convert ";
            }
            if (httpMethod == "GET" && confirm) {
                delete_msg = "Are you sure you want to <b>"+confirm+"</b>";
                extraMsg = '';
            }
            if (reject) {
                var msg = '<i class="fa fa-exclamation-triangle fa-2x" style="vertical-align: middle; color:#f39c12;"></i>' + rejectStatus_msg + extraMsg;
            } else {
                var msg = '<i class="fa fa-exclamation-triangle fa-2x" style="vertical-align: middle; color:#f39c12;"></i>' + delete_msg + extraMsg;
            }
            if(is_delete_with_reason == "yes"){
                msg += '<br><br><div class="form-group"><div class="col-sm-12"><label for="qty" class="col-sm-10 control-label">Reason For Delete:<span class="has-stik">*</span> </label><textarea class="form-control reason_for_delete" name="reason_for_delete"></textarea></div></div><br><br><br>';
            }

            // If the data-method attribute is not PUT or DELETE,
            // then we don't know what to do. Just ignore.
            if ($.inArray(httpMethod, allowedMethods) === -1) {
                return;
            }
            bootbox.dialog({
                message: msg,
                title: please_confirm,
                buttons: {
                    success: {
                        label: cancel_btn,
                        className: "btn-default",
                        callback: function () {}
                    },
                    danger: {
                        label: ok_btn,
                        className: "btn-success",
                        callback: function () {
                            var form = $('<form>', {
                                'method': 'POST',
                                'action': link.attr('href')
                            });
                            var hiddenInput = $('<input>', {
                                'name': '_method',
                                'type': 'hidden',
                                'value': link.data('method')
                            });
                            var tokenInput = $('<input>', {
                                'name': '_token',
                                'type': 'hidden',
                                'value': csrf_token
                            });
                            if (is_delete_with_reason == "yes") {
                                var reasonTextArea = $('<input>', {
                                    'name': 'reason_for_delete',
                                    'type': 'hidden',
                                    'value': jQuery('.reason_for_delete').val(),
                                });
                                form.append(reasonTextArea);
                            }
                            form.append(tokenInput);
                            form.append(hiddenInput).appendTo('body').submit();
                        }
                    }
                }
            });
        }
    };
    laravel.initialize();
})();
/* DatePicker Code */
jQuery(".datepicker").datetimepicker({
    format: 'd-m-Y',
    allowBlank: true,
    timepicker: false,
    mask: false,
    validateOnBlur: false,
    showOn: "button",
    scrollInput: false,
    onSelectDate : function(){
        jQuery(".datepicker").trigger('change');
    }
});
if (jQuery(".datepicker").val() == "__-__-____") {
    jQuery(".datepicker").val('');
}
/* DateTimePicker */
jQuery(".datetimepicker").datetimepicker({
    format: 'd-m-Y H:i:s',
    allowBlank: true,
    timepicker: true,
    mask: true,
    validateOnBlur: false,
    showOn: "button"
});
// for Image change Code
(function ($) {
    $.imageChanger = function (input, site, default_img) {
        if (input.files && input.files[0] && input.files[0]['type'] != "application/pdf") {
            var reader = new FileReader();
            reader.onload = function (e) {
                $("#" + site).css('visibility', 'visible');
                $("#" + site).attr('src', e.target.result);
                if (default_img) {
                    $("#" + default_img).remove();
                }
            };
            reader.readAsDataURL(input.files[0]);
        }else{
            var url = window.location.origin;
            full_url = url + '/images/pdf.png';
            $("#" + site).attr('src', full_url);
        }
    };
})(jQuery);

/* Prevent Form Submit */
restrictEnterkey();

function restrictEnterkey() {
    jQuery('input[type="text"]:not(.allow-enter), input:checkbox').on('keyup keypress', function (e) {
        var keyCode = e.keyCode || e.which;
        //console.log(keyCode);
        if (keyCode === 13) {
            // if(!$(this).hasClass("allow-enter")){
            e.preventDefault();
            return false;
            // }
        }
    });
    jQuery(document).on("keydown", function (e) {
        hasClass = jQuery(e.target).hasClass('note-editable');
        if(hasClass){
            return true;
        }
        if (e.which === 8 && !jQuery(e.target).is("input, textarea","div")) {
            e.preventDefault();
        }
    });
}

/* Add Active Class */
jQuery(function () {
    var pgurl = window.location.href;
    jQuery(".navigation li a").each(function () {
        if (jQuery(this).attr("href") == pgurl) {
            jQuery(this).parent().addClass('active');
        }
    });
});
/* For Image Lightbox */
jQuery('[data-popup="lightbox"]').fancybox({
    padding: 3,
    beforeShow: function () {
        this.title = $(this.element).attr('data-title');
    }
});
/* Dynamic Tabindex */
function dynamicTabIndex() {
    var tabvalue = 0;
    jQuery("input[type='submit']:not(.noCustomTabIndex), select:not(.noCustomTabIndex), .box a:not(.noCustomTabIndex).customTabindex, .box :input:not(.ui-select-search,.noCustomTabIndex)[type=text], .customTabindex , a.customTabindex, textarea.customTabindex, .ui-select-offscreen, button .customTabindex, input[type=checkbox].customTabindex, input[type=radio].customTabindex, input[type=file].customTabindex , .page-header a.customTabindex, input[type=radio].customTabindex,select.select-size,select.select-size-sm,select.customTabindex,span.select2 > span.selection >span.select2-selection,input[type=password]").each(function (i) {
        jQuery(this).removeAttr('tabindex');
        tabvalue = tabvalue + 1;
        jQuery(this).attr('tabindex', tabvalue);
        jQuery(this).addClass('data-tabindex');
    });
}
/* Function Used In Set Error in Modal */
function associate_errors(errors, $form) {
    //remove existing error classes and error messages from form groups
    $form.find('.form-group').removeClass('has-error').find('.help-block').text('');
    jQuery("form").find('.help-block').text('');
    jQuery.each(errors.errors, function (index, value) {
        //find each form group, which is given a unique id based on the form field's name
        var $group = $form.find('#' + index).parents('.form-group');
        if ($group.addClass('has-error').find('.help-block').length == 0) {
            $group.find('.form-control').parent('div').append("<span class='help-block'></span>");
        }
        //add the error class and set the error text
        $group.addClass('has-error').find('.help-block').text(value);
    });
}
//Method for Add more validation message while ajax
function associate_errors_multi(errors, $form) {
    //remove existing error classes and error messages from form groups
    jQuery(".dynamic").find('.error').removeClass('errors')
    jQuery(".dynamic").find('.error').text('')
    jQuery.each(errors, function (index, value) {
        if (index.indexOf("ajax_file") >= 0) {
            index = index.replace("ajax_file", "value");
        }
        var group = jQuery("div").find("[data-tdname='" + index + "']");
        if (group.addClass('has-error').find('.help-block').length == 0) {
            group.find('.control').parent('div').append("<span class='help-block'></span>");
        }
        group.addClass('has-error').find('.help-block').text(value['0']);
    });
}

//Code for User view PopUp
(function () {
    var UserInfo = {
        initialize: function () {
            this.methodLinks = jQuery('body');
            this.registerEvents();
        },
        registerEvents: function () {
            this.methodLinks.on('click', 'a[data-user-show]', this.handleMethod);
            this.methodLinks.on('click', '#show_user_modal .close', this.closeMethod);
        },
        handleMethod: function (e) {
            e.preventDefault();
            var user_id = jQuery(this).attr("data-user-show");
            var lang = jQuery(this).attr('data-lang');
            if (jQuery(this).attr("data-noActive")) {
                var noActive = jQuery(this).attr("data-user-show");
                var url = lang + "&flag=true";
            } else {
                var url = lang + "&download=yes";
            }
            jQuery.get(url, function (html) {
                jQuery('#show_user_modal .modal-body').html(html);
                jQuery('#show_user_modal').modal('show', {
                    backdrop: 'static'
                });
            });
        },
        closeMethod: function (e) {
            e.preventDefault();
            jQuery('#show_user_modal').modal('hide');
        }
    };
    UserInfo.initialize();
})();
/* File Upload */
function singleFileUpload() {
    var file = $('.file-upload');
    //console.log(file);
    var form = $('form.form-locker');
    var textbox = file.data('display');
    //console.log(textbox);
    var uploadErrors = [];
    file.fileupload({
        url: '/ajax/uploadfile/',
        dataType: 'json',
        method: 'POST',
        autoUpload: false,
        //acceptFileTypes: /(\.|\/)(gif|jpe?g|png)$/i,
        add: function (e, data) {
            $('.' + textbox).find('p').remove();
            $('#' + textbox).val('');
            $('.' + textbox).html('');
            var acceptFileTypes = /^image\/(jpe?g|png)$/i;
            if (!acceptFileTypes.test(data.originalFiles[0]['type'])) {
                uploadErrors.push('The file type allow only jpeg, jpg, png.');
            } else {
                data.submit();
            }
            if (uploadErrors.length > 0) {
                $('<p style="color: red;">Upload file error: ' + uploadErrors + '<i class="elusive-remove" style="padding-left:10px;"/></p>')
                    .appendTo('.' + textbox);
            }
        },
        done: function (e, data) {
            $('#' + textbox).val(data.files[0].name.toLowerCase());
            $('.' + textbox).html(data.files[0].name.toLowerCase());
        },
        progressall: function (e, data) {
            var progress = parseInt(data.loaded / data.total * 100, 10);
            $('#' + textbox).val(progress + '%');
            $('.' + textbox).html(progress + '%');
        },
        fail: function (e, data) {
            console.log(data);
            $.each(data.messages, function (index, error) {
                $('<p style="color: red;">Upload file error: ' + error + '<i class="elusive-remove" style="padding-left:10px;"/></p>')
                    .appendTo('#' + textbox);
            });
            $('#' + textbox).val(data.files[0].name.toLowerCase())
            $('.' + textbox).html(data.files[0].name.toLowerCase());
        }
    });
}
jQuery('body').on("focus", ".modal-dialog", function (e) {
    singleFileUpload();
});
// allow only positive numeric
jQuery(document).on("keypress blur", ".allownumeric", function (event) {
    var theEvent = event || window.event;
    var key = theEvent.keyCode || theEvent.which;
    if (key == 37 || key == 46 || key == 38 || key == 39 || key == 9 || key == 40 || key == 8 || key == 46 || key == 123 || key == 16) {
        return;
    } else {
        key = String.fromCharCode(key);
        var regex = /[0-9.]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }
});

//bind select2 to dropdown
jQuery('.single-select').select2();

function chunk_split(body, chunklen, end) {
    chunklen = parseInt(chunklen, 10) || 76;
    end = end || '\r\n';
    if (chunklen < 1) {
        return false;
    }
    return body.match(new RegExp(".{0," + chunklen + "}", "g")).join(end);
}
jQuery(".search-modules").click(function (e) {
    e.preventDefault();
    jQuery(".search-panel").toggle("slow");
});
/* Code Add to Set Cookie of display metal Credit Panel in Account route*/
jQuery(window).bind('load', function () {
    //$(document).ready(function() {
    /*
     *
     * When press alt+a then redirect in add
     *
     */
    shortcut.add("Alt+A", function () {
        console.log('Alt+A');
        if (jQuery('.btn-add').html()) {
            window.location = jQuery('.btn-add').attr('href');
        }
    });
    /*
     *
     * When press alt+s then save
     *
     */
    shortcut.add("Alt+S", function () {
        console.log('Alt+S');
        jQuery('form input[name="save"],.save').click();
    });
    /*
     *
     * When press alt+w then save and exit
     *
     */
    shortcut.add("Alt+W", function () {
        console.log('Alt+W');
        jQuery('form input[name="save_exit"],.save_exit').click();
    });
    /*
     *
     * When press alt+w then Back to Last screen
     *
     */
    shortcut.add("Alt+C", function () {
        console.log('Alt+C');
        if (jQuery('.btn-back').html()) {
            window.location = jQuery('.btn-back').attr('href');
        }
    });

    shortcut.add("Alt+P", function () {
        console.log('Alt+P');
        jQuery('input[name="save_print"],button[name="save_print"],#save_print').click();
    });
    shortcut.add("Alt+Shift+M",function() {
        console.log('Alt+Shift+M');
        if (jQuery('.menu-masters').html()) {
            jQuery('.menu-masters').trigger("click");
        }
    });
    shortcut.add("Alt+Shift+S",function() {
        console.log('Alt+Shift+S');
        if (jQuery('.menu-sub-category').html()) {
            window.location = jQuery('.menu-sub-category').attr('href');
        }
    });
    shortcut.add("Alt+Shift+R",function() {
        console.log('Alt+Shift+R');
        if (jQuery('.menu-raw-material').html()) {
            window.location = jQuery('.menu-raw-material').attr('href');
        }
    });
    shortcut.add("Alt+Shift+T",function() {
        console.log('Alt+Shift+T');
        if (jQuery('.menu-transaction').html()) {
            jQuery('.menu-transaction').trigger("click");
        }
    });
    shortcut.add("Alt+Shift+N",function() {
        console.log('Alt+Shift+N');
        if (jQuery('.menu-new-quotation').html()) {
            window.location = jQuery('.menu-new-quotation').attr('href');
        }
    });
    shortcut.add("Alt+Shift+H",function() {
        console.log('Alt+Shift+H');
        if (jQuery('.menu-hrm').html()) {
            jQuery('.menu-hrm').trigger("click");
        }
    });
    shortcut.add("Alt+Shift+C",function() {
        console.log('Alt+Shift+C');
        if (jQuery('.menu-customers').html()) {
            window.location = jQuery('.menu-customers').attr('href');
        }
    });
    shortcut.add("Alt+Shift+U",function() {
        console.log('Alt+Shift+U');
        if (jQuery('.menu-users').html()) {
            jQuery('.menu-users').trigger("click");
        }
    });
    shortcut.add("Alt+Shift+D",function() {
        console.log('Alt+Shift+D');
        if (jQuery('.menu-dashboard').html()) {
            window.location = jQuery('.menu-dashboard').attr('href');
        }
    });
    shortcut.add("Alt+Shift+Y",function() {
        console.log('Alt+Shift+Y');
        if (jQuery('.menu-cylinders').html()) {
            jQuery('.menu-cylinders').trigger("click");
        }
    });
    shortcut.add("Alt+Shift+L",function() {
        console.log('Alt+Shift+L');
        if (jQuery('.menu-challan').html()) {
            jQuery('.menu-challan').trigger("click");
        }
    });
});
function inrFormat(nStr) { // nStr is the input string
    nStr += '';
    x = nStr.split('.');
    x1 = x[0];
    x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    var z = 0;
    var len = String(x1).length;
    var num = parseInt((len/2)-1);
    while (rgx.test(x1))
    {
        if(z > 0)
        {
          x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }
        else
        {
          x1 = x1.replace(rgx, '$1' + ',' + '$2');
          rgx = /(\d+)(\d{2})/;
        }
        z++;
        num--;
        if(num == 0)
        {
          break;
        }
    }
    return x1 + x2;
}

jQuery("form").submit(function (e) {
    var className = this.className;
    var old = [];
    $("input[type=submit]", this).each(function (index, val) {
        old.push({
            "name": val.name,
            "value": val.value
        });
    });
    jQuery("input[type='submit']", this).val("Abort").prop("disabled", true).addClass('btn-danger');
    jQuery(".btn").addClass('pointer-not-allow').addClass('disabled');
    var interval = setInterval(countDown, 1000);

    function countDown() {
        if (form_submit_seconds > 0) {
            form_submit_seconds--;
            jQuery("input[type='submit']", this).val("Abort(" + form_submit_seconds + ")").prop("disabled", true).addClass('btn-danger');
        } else {
            jQuery("input[type='submit']").prop("disabled", false).removeClass('btn-danger').addClass('btn-primary');
            jQuery(".btn").removeClass('pointer-not-allow').removeClass('disabled');
            $.each(old, function (index, val) {
                jQuery("input[name=" + val.name + "]").val(val.value).prop('name', val.name);
            });
            clearInterval(interval);
        }
    }
    jQuery.each(old, function (index, val) {
        jQuery("input[name=" + val.name + "]").val(val.value).prop('name', val.name);
    });
    jQuery("input[type='submit']").prop("disabled", false).removeClass('btn-danger').addClass('btn-primary');
    return true;
});
jQuery(document).on("change", ".barcode,.barcode_number", function(){
    barcode = jQuery.trim(jQuery(this).val());
    jQuery(this).val(barcode);
});

getUserNotification();
var notifyExist = [];

/**
 *  when click on close button update notification status from 'unread' to 'read'
 **/
jQuery(document).on("click", ".ui-pnotify-closer", function (e) {
    var notify_id = jQuery(this).parent().parent().find('._notify_id').val();
    setNotificationRead(notify_id);
});

function setNotificationRead(notify_id) {

    if (typeof notify_id !== "undefined" && notify_id > 0) {
        var csrf_token = jQuery('meta[name="csrf-token"]').attr('content');
        jQuery.ajax({
            type: "POST",
            url: "/notifyajax/notification-read",
            data: {
                _token: csrf_token,
                notify_id: notify_id
            }
        }).success(function (result) {
            return 1;
        }).error(function () {
            return 0;
        });
    }
}

/**
 *  Every 5 minutes this method will fier ajax in backed
 *  1st time call when page is load
 *  call from getUserNotification()
 **/
function getUserNotification() {
    jQuery.ajax({
        type: "GET",
        url: "/notifyajax/user-notification"
    }).success(function (result) {
        showNotification(result);
    });
}

/**
 * Method used to create pnotify object
 **/
function createNotification(title, text, type, notification_id, notify_id, url, creater, comment, created_at = null) {
    var content = '<div class="pnotify-content">' + text + '<input type="hidden" value="' + notification_id + '" class="_notify_notification_id" />';
    content += '<input type="hidden" value="' + notify_id + '" class="_notify_id" /></div>';
    content += '<div class="pnotify-footer text-right">';
    content += '<div class="clearfix">';
    content += '<span class="col-xs-10 notifyDatetimeSection hide pull-left notify-padding-remove">';
    content += '<input type="text" placeholder="want to say something ?" class="commentTextBox notifyCommentBox notifyTextbox notify-min-height" />';
    content += '<input type="text" class="timepicker notifyTimeTextbox notifyTextbox notify-min-height" />';
    content += '<button class="notify-set-button">Set</button>';
    content += '</span>';
    content += '<span class="col-xs-1 notify-padding-remove pull-right notify-min-height">';
    /* content += '<span class="toggleNotifyDatetime"><i class="fa fa-bell" aria-hidden="true" title="reminder"></i></span>'; */
    content += '</span>';
    content += '</div>';
    content += '<div class="text-left notify-comment">';
    content += '</div>';
    content += '<div>';
    content += '<div class="pull-left get-link"></div>';
    content += '<div class="text-right pull-right after-me"><small class="notify-creater text-uppercase"></small></div>';
    content += '<div class="clearfix"></div>';
    content += '<div class="text-right pull-right created-at"><small class="notify-created-at text-uppercase"></small></div>';
    content += '<div class="clearfix"></div>';
    content += '</div>';
    content += '</div>';
    PNotify.prototype.options.styling = "bootstrap3";
    new PNotify({
        title: title,
        text: content,
        type: type,
        hide: false,
        opacity: .8,
        buttons: {
            sticker: false,
            closer_hover: false
        },
        before_open: function (hdom) {
            // hdom.container.find('.notify-creater').text("Created By :" + creater);
            // hdom.container.find('.notify-created-at').text("Created At :" + created_at);
            hdom.container.find('.ui-pnotify-closer').append("&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span><button id='closeAllNotifications' style='background-color: white;background-repeat:no-repeat;cursor:pointer;'><b><font style='color:red;'>Close All</font></b></button></span>");
        },
        after_open: function (dom) {
            var link = dom.container.find('.link_message').addClass('pull-left').text();
            dom.container.find('.get-link').html('<a href=' + url + ' target="_blank">' + link + '</a>');
            dom.container.find('.link_message').remove();
            dom.container.find('.notify-comment').text(comment);
            jQuery(".timepicker").datetimepicker({
                datepicker: false,
                timepicker: true,
                format: 'H:i',
                formatTime: 'h:i A',
                step: 5,
                allowBlank: true,
            });
        },
        desktop: {
            desktop: false,
            fallback: true
        }
    });
}

/**
 * When ajax is coming from backend and has data then this method will call
 * call from getUserNotification()
 **/
function showNotification(result) {
    jQuery.each(result, function (index, value) {
        if ('title' in value && 'message' in value && 'status' in value) {
            if (typeof notifyExist[value.id] === 'undefined') {
                console.log(value);
                createNotification(value.title, value.message, value.status, value.notification_id, value.id, value.url, value.creater, value.comment, value.createdAt);
                notifyExist[value.id] = 1;
            }
        }
    });
}

/**
 * used to create notification again after set time
 * call from ('click','.notify-set-button')
 **/
function setNotificationAgain(notification_id, notify_id, time, comment, domHide) {
    if (notification_id > 0 && notify_id > 0 && (time !== '' || comment !== '')) {
        var csrf_token = jQuery('meta[name="csrf-token"]').attr('content');
        jQuery.ajax({
            type: "POST",
            url: "/notifyajax/notification-create",
            data: {
                _token: csrf_token,
                notification_id: notification_id,
                notify_id: notify_id,
                time: time,
                comment: comment
            }
        }).success(function (result) {
            domHide.parent().parent().fadeOut();
        }).error(function () {
            return false;
        });
    }
}

/*
jQuery(document).on("click", "#closeAllNotifications", function (e) {
    jQuery.ajax({
        type: "POST",
        url: "/notifyajax/close-all-notification"
    }).success(function (result) {
        location.reload();
    });
});
*/

function changeActiveDeActiveToggel(route) {
    console.log(route);
    jQuery.ajax({
        type: "GET",
        url: route,
    }).success(function (result) {
        customAlertMsg(result.title, result.text, result.type);
    });
}

function customAlertMsg(title, text, type) {
    PNotify.prototype.options.styling = "bootstrap3";
    new PNotify({
        title: title,
        text: text,
        type: type,
        hide: true,
        icon: false,
        opacity: .5,
        delay: 4000,
        buttons: {
            sticker: false,
            closer_hover: false
        }
    });
}